<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-btn fab text x-small left v-on:click="Limpiar()">
            <v-icon> mdi-autorenew</v-icon>
          </v-btn>
          <h1>Generador de Tokens</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="motivoSelected"
            :items="motivosLista"
            item-text="Descripcion"
            item-value="idMotivo"
            outlined
            hide-details
            label="Motivo"
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Comentarios"
            v-model="comentarios"
            outlined
            rows="1"
            :maxlength="maxText"
            clearable
            clear-icon="mdi-close-circle"
            auto-grow
            counter="250"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn dark color="#003479" @click="GenerarToken()">
            Generar Token
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="tokenGenerado != ''">
        <v-col cols="12">
          <h3>Token Generado: {{ tokenGenerado }}</h3>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GeneradorTokens",
  data: () => ({
    tokenGenerado: "",
    motivosLista: [],
    motivoSelected: "",
    maxText: "250",
    comentarios: "",
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
  },
  mounted() {
    this.TraeListaMotivosToken();
  },
  methods: {
    async TraeListaMotivosToken() {
      const response = await fetch(
        this.url + "/administracion/lista_motivos_token",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      const valor = await response.json();
      if (response.status == 200) {
        if (valor.length > 0) {
          this.motivosLista = valor;
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Hubo un error al traer la lista de motivos, intente de nuevo.",
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async GenerarToken() {
      if (this.motivoSelected == "") {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Debe seleccionar un motivo primero.",
        });
        return;
      }
      const response = await fetch(
        this.url + "/administracion/genera_token_permiso",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            idUsuario: this.userInfo.idUsuario,
            idMotivo: this.motivoSelected,
            comentarios: this.comentarios,
          }),
        }
      );
      const valor = await response.json();
      if (response.status == 200) {
        if (valor.length > 0) {
          this.tokenGenerado = valor[0].token;
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "No se pudo generar el token, vuelve a intentar.",
          });
        }
      } else {
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    Limpiar() {
      this.motivoSelected = "";
      this.comentarios = "";
      this.tokenGenerado = "";
      this.TraeListaMotivosToken();
    },
  },
};
</script>

<style scope></style>
